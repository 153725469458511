<template>
  <div class="page" :class="{'is-mobile' : $vuetify.breakpoint.mobile}">
    <div class="content">
      <v-container fluid>

        <v-row>
          <v-col cols="12" md="6" lg="4">
            <painel-card icon="mdi-cash-multiple" title="Faturas" color="#2A80B9" :loading="mostraLoad"
                         :dados="elements.FA"/>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <painel-card icon="mdi-target" title="Matrícula" color="#16A086" :loading="mostraLoad"
                         :dados="elements.MA"/>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <painel-card icon="mdi-application-parentheses" title="Locações" color="#F39C11" :loading="mostraLoad"
                         :dados="elements.AL"/>
          </v-col>
          <v-col cols="12" md="6" lg="4">
            <painel-card icon="mdi-nintendo-game-boy" title="Competições" color="#C1392B" :loading="mostraLoad"
                         :dados="elements.CO"/>
          </v-col>
          <v-col cols="12" md="6" lg="4" v-if="nivelUsuarioLogado !== 'CLI'" class="ultima-coluna">
            <painel-card icon="mdi-message" title="Mensagens" :loading="mostraLoad" color="#8F44AD"
                         :dados="elements.ME">
              <template v-slot:footer v-if="(nivelUsuarioLogado === 'ADM' || nivelUsuarioLogado === 'GER') && !mostraLoad">
                <div class="d-flex flex-grow-1 flex-column justify-space-between mt-9">
                  <span class="white--text body-1">Integração Zap:</span>
                  <section class="d-flex align-self-end flex-column font-weight-bold">
                    <div class="item-zap text-end white--text">
                      <span class="subheading mr-2 whi">Saldo:</span>
                      <span class="subheading">{{ exibeSaldoZap }}</span>
                    </div>
                    <div class="item-zap text-end" :class="classeStatus">
                      <span class="subheading mr-2">Status:</span>
                      <span class="subheading"><v-icon :class="classeStatus">{{ exibeStatusZap }}</v-icon></span>
                    </div>
                  </section>
                </div>
              </template>
            </painel-card>
          </v-col>
          <!--O PULO DO GATO - kkkkkkkkkkk-->
          <!--          <div style="width:100%; float:left;" v-for="n in 5" :key="n">-->
          <!--            <div><br></div>-->
          <!--          </div>-->
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import {
  findPainelCliente,
  findPainelFuncionario,
  findSaldoIntegracaoZap,
  findStatusIntegracaoZap
} from "../../api/painel";
import {mapGetters} from "vuex";
import PainelCard from "./components/PainelCard";

export default {
  name: "Painel",
  components: {PainelCard},
  data() {
    return {
      elements: [],
      loading: false,
      error: null,
      saldoZap: null,
      statusZap: null,
      interval: {},
      value: 0,
      mostraLoad: true,
      classeStatus: "",
    }
  },
  computed: {
    ...mapGetters('auth', ['user', 'isAuthenticated', 'nivelUsuarioLogado']),
    exibeSaldoZap() {
      return "R$ " + (this.saldoZap === null ? "0,00" : this.saldoZap);
    },
    exibeStatusZap() {
      let icone = "mdi-hand-okay";
      this.classeStatus = "conectado";
      if (this.statusZap !== "Conectado") {
        icone = "mdi-alert-circle";
        this.classeStatus = "desconectado";
      }
      return icone;
    }
  },
  beforeDestroy() {
    clearInterval(this.interval)
  },
  mounted() {
    this.refresh();
  },
  methods: {
    async refresh() {
      try {
        this.error = null;
        this.loading = true;
        let page = null;
        let valor = null;
        if (this.nivelUsuarioLogado === 'CLI') {
          page = await findPainelCliente(1, {});
        } else {
          page = await findPainelFuncionario(1, {});
          if (this.nivelUsuarioLogado === 'ADM' || this.nivelUsuarioLogado === 'GER') {
            valor = await findSaldoIntegracaoZap(1, {});
            this.saldoZap = valor.elements.saldo;
            valor = await findStatusIntegracaoZap(1, {});
            this.statusZap = valor.elements.status;
          }
        }
        this.mostraLoad = false;
        this.elements = page.elements;
      } catch (err) {
        this.error = err;
      } finally {
        this.loading = false;
      }
    },
    retornaPercentual(elementos, qtd, cor) {
      const total = elementos.reduce((acumulado, elemento) => acumulado + elemento.quantidade, 0);
      var percent = (qtd * 100 / total).toFixed(0);
      return " background-color: " + cor + "; width: " + (qtd > 0 ? percent : 1) + "%;";
    }
  }
}
</script>
<style scoped lang="scss">
/*.page {
  border:2px solid red;
}*/
.page .is-mobile {
  .content {
    overflow: auto;
    max-height: calc(100vh - 60px);
  }

  ::v-deep {
    .v-data-table__wrapper {
      height: calc(100vh - 97px);
    }
  }
}

.page .content {
  /*border:2px solid green;*/
  overflow: auto;
  max-height: calc(100vh - 50px);
  width: 100%;
  flex: 1;

  ::v-deep {
    .v-data-table__wrapper {
      height: calc(100vh - 157px);
    }
  }
}

.ultima-coluna {
  margin-bottom: 200px;
}

.informacoes-zap {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;

  .item-zap {
    align-self: end;
  }
}

.conectado {
  color: #BAEEB9
}

.desconectado {
  color: #FF9595
}
</style>